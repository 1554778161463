<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="preview-header">
                <div class="preview-title">{{ title }}</div>
                <div class="preview-action">
                    <div><download-button dom="#previewContent" :name="this.entity.advancePaymentCode + '.pdf'"/></div>
                    <div class="preview-print">
                        <a @click="print">Print</a>
                    </div>
                    <div class="preview-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
            </div>
            <md-content id="previewContent">
                <div class="preview-content" style="width: 780px;">
                    <div style="display: flex; border: 1px solid #ccc; padding: 10px;">
                        <div style="width: 50%; display: flex; align-items: center;">
                            <img src="../../../assets/images/logo.png" style="width: 130px; height: 50px;"/>
                        </div>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <div style="font-weight: bold; font-size: 22px;">TẠM ỨNG ĐĂNG KIỂM</div>
                            <div style="font-size: 14px;"><span>Số: </span><span style="font-weight: bold;">{{entity.advancePaymentCode}}</span></div>
                            <div><span>Ngày: </span><span>{{entity.advancePaymentDate}}</span></div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px 0;"> 
                        <span style="font-weight: bold; font-size: 18px;">Kính gửi: </span>
                        <span style="font-weight: bold; font-size: 18px; padding-left: 10px;">BGĐ Công ty PPL</span>
                    </div>
                    <div style="display: flex; flex-direction: column;"> 
                        <p style="padding-bottom: 10px; font-size: 14px;">Tôi tên là: <span style="font-weight: bold; font-size: 15px;">{{entity.staff.fullName}}</span></p>
                    </div>
                    
                    <div>
                        <table style=" border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                            <thead>
                                <tr style="background-color: antiquewhite; font-weight: bold; font-size: 13px;">
                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">STT</th>
                                    <th style="width:350px; padding: 5px 10px; border: 1px solid #e0e0e0;">TÊN PHÍ</th>
                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">SỐ TIỀN</th>
                                    <th style="width:130px; padding: 5px 10px; border: 1px solid #e0e0e0;">VAT</th>
                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">THÀNH TIỀN</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, index) in details" :key="item.id">
                                <tr>
                                    <td style="padding: 5px 10px; text-align: center;border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                    <td style="padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{item.key}}</span></td>
                                    <td style="padding: 5px 10px; border: 1px solid #e0e0e0;" colspan="3"></td>
                                </tr>
                                <tr v-for="(obj, i) in item.list" :key="obj.id">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">{{i + 1}}</td>
                                    <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">{{obj.fee.feeName}}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(obj.amount)}}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(obj.vat)}}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(obj.pay)}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colspan="4" style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Tổng</b></td>
                                    <td style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.amount)}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4" style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Thuế VAT</b></td>
                                    <td style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.vat)}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4" style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Tổng Cộng</b></td>
                                    <td style="font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.pay)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content: space-around; padding-top: 80px;"> 
                        <div v-for="item in entity.approveList" :key="item.id" style="margin-bottom: 50px; padding-bottom: 50px;">
                            <div v-show="!item.isCommenter" style="display: flex; flex-direction: column; justify-content: center; ">
                                <div style="font-size: 15px; font-weight: bold;">{{item.title}}</div>
                                <div style="font-size: 15px; font-weight: bold; padding-top: 70px;">{{item.staff.fullName}}</div>
                                <div>{{item.note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-content>
    </md-dialog>
</template>

<script>
    import downloadButton from '../../../components/DownloadButton.vue';

    export default ({
        components: {
            downloadButton
        },
        metaInfo: {
            title: 'Tạm ứng đăng kiểm'
        },
        data() {
            return {
               title: 'Tạm ứng đăng kiểm',
               id: 0,
               showDialog: false,
               loadding: false,
               details: [],
               entity: { id: 0, status: 0, details: [], amount: 0, vat: 0, pay: 0, estimateList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, advancePaymentCode: '', advancePaymentDate: '' },
            }
        },
        methods: {
            async open(obj){
                this.entity = obj;
                const details = this.groupBy(this.entity.details);
                this.details = [];

                Object.keys(details).forEach((item, index) => {
                    const objItem = { key: item, list: details[item] };
                    details[index] = details[item];
                    delete details[item];
                    this.details.push(objItem);
                }); 
                this.showDialog = true;
            },

            print () {
                this.$htmlToPaper('previewContent');
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimate.estimateCode]: [...(groups[item.estimate.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },
        },
    })
</script>


<style lang="css" scoped>
    .preview-title {
        font-size: 16px;
    }
    .preview-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 810px;
        min-height: 600px;
        height: auto;
        max-width: 810px;
    }
    .md-scrollbar {
        height: 600px;
        padding-bottom: 20px;
    }
    .preview-content {
        padding: 10px 15px;
    }
</style>